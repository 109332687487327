import React from "react";
import "./css/ourServicesHome.css";
import { Link } from "react-router-dom";
import cloud from "../../images/cloudimg.png";
import mobile from "../../images/mobileimg.png";
import digital from "../../images/digitalimg.png";
import product from "../../images/productimg.png";
import business from "../../images/businessimg.png";
import staff from "../../images/staffimg.png";

const OurServicesHome = () => {
  return (
    <>
      <div className="container-fluid ourServices-container mt-5">
        <div className="container">
          <div className="">
            <div className="ourServices-sub-container">
              <div className="ourServices-text-container">
                <p className="ourServices-heading">
                  <h1 className="ourServices-text-wrapper header">
                    Our{" "}
                    <span className="ourServices-span header">Services </span>
                  </h1>
                </p>
                <p className="ourServices-paragraph sub-para">
                  Discover a range of comprehensive and customizable banking
                  products at YourBank, designed to suit your unique financial
                  needs and aspirations
                </p>
              </div>
            </div>
            <div className="ourServices-frame">
              <div className="ourServices-div">
                <div className="ourServices-div-2">
                  <img
                    className="ourServices-icon-container"
                    alt="Icon container"
                    src={cloud}
                  />
                  <div className="ourServices-text-container-2">
                    <div className="ourServices-heading-2 para">
                      Cloud Solutions
                    </div>
                    <div className="ourServices-frame-2">
                      <p className="ourServices-p sub-para">
                        Harness the power of scalable and flexible cloud
                        services for seamless business operations and enhanced
                        agility.
                      </p>
                      <div className="ourServices-paragraph-2 ">
                        <Link to="/services" className="ourServices-learm-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ourServices-div-2">
                  <img
                    className="ourServices-icon-container"
                    alt="Icon container"
                    src={mobile}
                  />
                  <div className="ourServices-text-container-2">
                    <div className="ourServices-heading-3 para">
                      Mobile Apps
                    </div>
                    <div className="ourServices-frame-2">
                      <p className="ourServices-p sub-para">
                        Transform ideas into intuitive and powerful mobile
                        applications, delivering exceptional user experiences
                        across platforms.
                      </p>
                      <div className="ourServices-paragraph-2 ">
                        <Link to="/services" className="ourServices-learm-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ourServices-div-2">
                  <img
                    className="ourServices-icon-container"
                    alt="Icon container"
                    src={digital}
                  />
                  <div className="ourServices-text-container-2">
                    <div className="ourServices-heading-2 para">
                      Digital Transformation
                    </div>
                    <div className="ourServices-frame-2">
                      <p className="ourServices-p sub-para">
                        Drive organizational growth and efficiency through
                        comprehensive digital transformation strategies tailored
                        to your unique needs.
                      </p>
                      <div className="ourServices-paragraph-2 ">
                        <Link to="/services" className="ourServices-learm-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourServices-div">
                <div className="ourServices-div-2">
                  <img
                    className="ourServices-icon-container"
                    alt="Icon container"
                    src={product}
                  />
                  <div className="ourServices-text-container-2">
                    <div className="ourServices-heading-2 para">
                      Product Engineering
                    </div>
                    <div className="ourServices-frame-2">
                      <p className="ourServices-p sub-para">
                        Innovate and stay ahead with cutting-edge product
                        engineering solutions that bring your ideas to life.
                      </p>
                      <div className="ourServices-paragraph-2 ">
                        <Link to="/services" className="ourServices-learm-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ourServices-div-2">
                  <img
                    className="ourServices-icon-container"
                    alt="Icon container"
                    src={business}
                  />
                  <div className="ourServices-text-container-2">
                    <div className="ourServices-heading-3 para">
                      Business Intelligence &amp; Analytics
                    </div>
                    <div className="ourServices-frame-2">
                      <p className="ourServices-p sub-para">
                        Gain actionable insights and make informed decisions
                        with our advanced business intelligence and analytics
                        services.
                      </p>
                      <div className="ourServices-paragraph-2 ">
                        <Link to="/services" className="ourServices-learm-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ourServices-div-2">
                  <img
                    className="ourServices-icon-container"
                    alt="Icon container"
                    src={staff}
                  />
                  <div className="ourServices-text-container-2">
                    <div className="ourServices-heading-2 para">
                      Staff Augmentation
                    </div>
                    <div className="ourServices-frame-2">
                      <p className="ourServices-p sub-para">
                        Enhance your team&#39;s capabilities with skilled
                        professionals, ensuring your projects meet deadlines and
                        exceed expectations.
                      </p>
                      <div className="ourServices-paragraph-2 ">
                        <Link to="/services" className="ourServices-learm-more">
                          Learn more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServicesHome;
