import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import "../css/navbar.css";
import logo from "../images/logo.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      className="container-fluid p-0 sticky-top Navbar"
      style={{ backgroundColor: "#1A1A1A" }}
    >
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div>
          <Link to="/">
            {" "}
            <img className="p-3" src={logo} alt="Logo"  />
          </Link>
        </div>

        <button className="navbar-toggler" type="button" onClick={handleToggle}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <Collapse in={open}>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto Navbar-list">
              <li className="nav-item navbar-item">
                <NavLink
                  className="nav-link navbar-link "
                  activeClassName="active"
                  exact
                  to="/"
                  onClick={handleClose}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item navbar-item">
                <NavLink
                  className="nav-link navbar-link"
                  activeClassName="active"
                  to="/aboutus"
                  onClick={handleClose}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item navbar-item">
                <NavLink
                  className="nav-link navbar-link"
                  activeClassName="active"
                  to="/services"
                  onClick={handleClose}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item navbar-item">
                <NavLink
                  className="nav-link navbar-link"
                  activeClassName="active"
                  to="/careers"
                  onClick={handleClose}
                >
                  Careers
                </NavLink>
              </li>
              <li className="nav-item navbar-item">
                <NavLink
                  className="nav-link navbar-link"
                  activeClassName="active"
                  to="/contactus"
                  onClick={handleClose}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </Collapse>
      </nav>
    </div>
  );
};

export default Navbar;
