import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
import logo from "../images/footer-logo.png";
import phone from "../images/phone-icon.png";
import location from "../images/location-icon.png";
import mail from "../images/mail-icon.png";

const Footer = () => {
  return (
    <div className="container-fluid footer mt-5">
      <div className="container">
        <div className="footer-container ">
          <div className="main-footer row">
            <div className="mobilution-div col-md-6 col-lg-3 col-sm-12 mb-3">
              <a href="/">
                <img
                  className="mobilution-img"
                  src={logo}
                />
              </a>
              <p className="mobilution-para sub-para">
                Your go-to for innovative IT solutions, from Cloud Services to
                Mobile Apps. Empowering businesses through technology for
                success in the ever-evolving tech landscape.
              </p>
            </div>
            <div className="company-div col-md-6 col-lg-3 col-sm-12 mb-3">
              <ul className="homepage sub-para">
                <h1 className="company para">COMPANY</h1>
                <li className="footer-li-items">
                  <Link to="/" className="footer-links">
                    Home page
                  </Link>
                </li>
                <li className="footer-li-items">
                  {" "}
                  <Link to="/aboutus" className="footer-links">
                    About Us
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Services
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/careers" className="footer-links">
                    Careers
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/contactus" className="footer-links">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="our-solution-div col-md-6 col-lg-3 col-sm-12 mb-3">
              <ul className="sub-para">
                <h1 className="oursolution para">our services</h1>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Cloud Solutions
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Mobile Apps
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Digital Transformation
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Product Engineering
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Business Intelligence & Analytics
                  </Link>
                </li>
                <li className="footer-li-items">
                  <Link to="/services" className="footer-links">
                    Staff Augmentation
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-end-images col-md-6 col-lg-3 col-sm-12 mb-3">
              <div className="ms-4">
                <div className="footer-address">
                  <div>
                    <img className="hello" src={mail} />
                  </div>
                  <a
                    className="footer-address-items sub-para"
                    href="mailto:join@mobilutionit.com"
                    style={{ textDecoration: "none" }}
                  >
                    join@mobilutionit.com
                  </a>
                </div>
                <div className="footer-address">
                 <div>
                 <img className="phone" src={phone} />
                 </div>
                  <a
                    href="tel:+91 40 4603 1323"
                    className="footer-address-items sub-para"
                    style={{ textDecoration: "none" }}
                  >
                    +91 40 4603 1323
                  </a>
                </div>
                <div className="footer-address">
                <div>
                <img className="location" src={location} />
                </div>
                  <p
                    className="footer-address-items sub-para "
                    style={{ textAlign: "left" }}
                  >
                    India - #303B Jain Sadguru Image's Capital Park, Silicon
                    Valley, Madhapur Hyderabad 500081
                  </p>

                </div>
                <div className="footer-address">
                <div>
                <img className="location" src={location} />
                </div>
                  <p className="footer-address-items sub-para">
                    India - Bhive JBR, Plot No. 77, Jbr Tech Park, 6th Rd, EPIP
                    Zone, Whitefield, Bengaluru 560066
                  </p>
                </div>
                <div className="footer-address">
                <div>
                <img className="location" src={location} />
                </div>
                  <p className="footer-address-items sub-para">
                    USA - 113, Heritage Station, St. Charles, MO 63303
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-line" />
          <div className="footer-terms p-3">
            <p className="sub-para copyright">
              © 2023 MobilutionIT. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
