import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../css/contactus.css";
import axios from "axios";

const ContactUs = () => {
  const [details, setDetails] = useState({
    fullName: "",
    email: "",
    reasons: [],
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const captchaRef = useRef();

  const handleCaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const SITE_KEY = "6Lc9LEopAAAAAJGV1ee6No68y06OXLJgir2gw8sS";
  //const SITE_KEY = "6LenkhkpAAAAAHMnF9ymwF9Tj6MX3jpB1UJPRk81" //TESTING KEY;

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    setDetails((prevDetails) => {
      if (checked) {
        return {
          ...prevDetails,
          reasons: [...prevDetails.reasons, value],
        };
      } else {
        return {
          ...prevDetails,
          reasons: prevDetails.reasons.filter((reason) => reason !== value),
        };
      }
    });
  };

  const FormDetails = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const res = await axios.post(
        "https://mobserver.techu.in/contact",
        {
          ...details,
          recaptchaValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
          credentials: "omit",
        }
      );
      setDetails({
        fullName: "",
        email: "",
        reasons: [],
        message: "",
      });
    }
    captchaRef.current.reset();
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if fullName is empty
    if (!details.fullName.trim()) {
      newErrors.fullName = "Full Name is required";
    }

    // Check if email is empty or invalid
    if (!details.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(details.email)) {
      newErrors.email = "Invalid email address";
    }

    // Check if reasons array is empty
    if (details.reasons.length === 0) {
      newErrors.reasons = "Please select at least one reason";
    }

    // Check if message is empty
    if (!details.message.trim()) {
      newErrors.message = "Message is required";
    }

    // Check if recaptchaValue is empty
    if (!recaptchaValue) {
      newErrors.captcha = "Please verify that you are not a robot.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <div className="container-fluid contactus-main-head">
        <div className="cirle-bg-blur">
          <div className="container">
            <div className="contactus-main mt-5">
              <div className="contactus-border">
                <h6 className="contact-header para">CONTACT US</h6>
                <h3 className="contactus-sub-header header">
                  Let's Start the{" "}
                  <span className="contactus-sub-header-span">
                    Conversation
                  </span>
                </h3>
                <p className="contactus-content sub-para">
                  Ready to elevate your online presence? Get in touch today and
                  discover how our expertise can drive success for your
                  business.
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="contactus-fields">
                <div className="contactus-input-field">
                  <label className="contactus-label para">Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    className={`contactus-input ${errors.fullName && "error"}`}
                    placeholder="Type Here"
                    value={details.fullName}
                    onChange={FormDetails}
                  />
                  {errors.fullName && (
                    <p className="error-message">{errors.fullName}</p>
                  )}
                </div>
                <div className="contactus-input-field">
                  <label className="contactus-label para">Email</label>
                  <input
                    type="email"
                    name="email"
                    className={`contactus-input ${errors.email && "error"}`}
                    placeholder="Type Here"
                    value={details.email}
                    onChange={FormDetails}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
              </div>
              <div className="contactus-fields-sub">
                <p className="contactus-heading para">
                  Why are you contacting us?
                </p>
                <div className="contactus-text-wraper">
                  <div className="contactus-checkbox-fields">
                    <div className="contactus-checkbox-fields-1">
                      <input
                        type="checkbox"
                        name="reasons"
                        className="contactus-checkbox"
                        onChange={handleCheckboxChange}
                        value="Cloud Adoption/Migration"
                        checked={details.reasons.includes(
                          "Cloud Adoption/Migration"
                        )}
                      />
                      <label className="contactus-label para">
                        Cloud Adoption/Migration
                      </label>
                    </div>
                    <div className="contactus-checkbox-fields-1">
                      <input
                        type="checkbox"
                        name="reason"
                        className="contactus-checkbox"
                        onChange={handleCheckboxChange}
                        value="Enterprise Applications"
                        checked={details.reasons.includes(
                          "Enterprise Applications"
                        )}
                      />
                      <label className="contactus-label para">
                        Enterprise Applications
                      </label>
                    </div>
                  </div>
                  <div className="contactus-checkbox-fields">
                    <div className="contactus-checkbox-fields-1">
                      <input
                        type="checkbox"
                        name="reason"
                        className="contactus-checkbox"
                        onChange={handleCheckboxChange}
                        value="Mobile Apps"
                        checked={details.reasons.includes("Mobile Apps")}
                      />
                      <label className="contactus-label para">
                        Mobile Apps
                      </label>
                    </div>
                    <div className="contactus-checkbox-fields-1">
                      <input
                        type="checkbox"
                        className="contactus-checkbox"
                        onChange={handleCheckboxChange}
                        checked={details.reasons.includes("Staffing")}
                        name="reason"
                        value="Staffing"
                      />
                      <label className="contactus-label para">Staffing</label>
                    </div>
                  </div>
                  <div className="contactus-checkbox-fields">
                    <div className="contactus-checkbox-fields-1">
                      <input
                        type="checkbox"
                        name="reason"
                        className="contactus-checkbox"
                        onChange={handleCheckboxChange}
                        value="Full Stack Development"
                        checked={details.reasons.includes(
                          "Full Stack Development"
                        )}
                      />
                      <label className="contactus-label para">
                        Full Stack Development
                      </label>
                    </div>

                    <div className="contactus-checkbox-fields-1">
                      <input
                        type="checkbox"
                        className="contactus-checkbox"
                        onChange={handleCheckboxChange}
                        value="Others"
                        checked={details.reasons.includes("Others")}
                        name="reason"
                      />
                      <label className="contactus-label para">Others</label>
                    </div>
                  </div>
                </div>
                ;
                {errors.reasons && (
                  <p className="error-message">{errors.reasons}</p>
                )}
              </div>
              <div className="contactus-fields-sub">
                <div className="contactus-message">
                  <label className="contactus-label para">Your Message</label>
                  <input
                    type="text"
                    name="message"
                    className={`contactus-input ${errors.message && "error"}`}
                    placeholder="Type Here"
                    value={details.message}
                    onChange={FormDetails}
                  />
                  {errors.message && (
                    <p className="error-message">{errors.message}</p>
                  )}
                </div>
              </div>
              <div className="countactus-captch">
                <ReCAPTCHA
                  sitekey={SITE_KEY}
                  onChange={handleCaptchaChange}
                  ref={captchaRef}
                />
                {errors.captcha && (
                  <p className="error-message">{errors.captcha}</p>
                )}
              </div>
              <div className="contactus-submit">
                <button className="contactus-submit-button" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
