import React from "react";
import GlobalCompanies from "./GlobalCompanies";
import WhyUs from "./WhyUs";
import AboutUs from "./AboutUs";
import OurServicesHome from "./OurServicesHome";
import Header from "./Header";
import "./css/home.css";
import HireYour from "./HireYour";


const Home = () => {
  return (
    <div className="home-main">
      <Header />
      <AboutUs />
      <OurServicesHome />
      <WhyUs />
      <GlobalCompanies />
      <HireYour />
  
    </div>
  );
};

export default Home;
