import React from "react";
import "../css/ourValue.css";
import mission from "../images/mission.png";
import vision from "../images/vision.png";

const OurValues = () => {
  return (
    <>
      <div className="container-fluid ourvalues-main-head">
        <div className="cirle-bg-blur">
          <div className="container ">
            <div className="ourvalue-main mt-5 mb-5">
              <div className="ourvalue-border">
                <h6 className="ourvalue-header para">ABOUT US</h6>
                <h3 className="ourvalue-sub-header header">
                  Where Technology Meets{" "}
                  <span className="ourvalue-sub-header-span">
                    Transformation!
                  </span>
                </h3>
                <p className="ourvalue-content sub-para">
                  Empowering Businesses Through Innovative Technology Solutions
                  – Where Excellence Meets Evolution at Mobilution IT Systems!
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="ourvalues-text-container">
                <div className="ourvalues-heading sub-header">Our Values</div>
                <p className="ourvalues-paragraph sub-para">
                  At Mobilution, our values are the cornerstone of everything we
                  do. We believe in integrity, innovation, and a relentless
                  commitment to excellence. Our team is driven by a passion for
                  crafting solutions that not only meet expectations but exceed
                  them. Transparency, collaboration, and client satisfaction are
                  at the core of our values, ensuring that every interaction and
                  project reflects our unwavering dedication to quality and
                  success. Join us on a journey where values transform into
                  actions, creating a lasting impact on your business.
                </p>
              </div>
              <div className="ourvalues-sub-container mt-5">
                <div className="ourvalues-sub-container-card">
                  <div className="ourvalues-card">
                    <div className="ourvalues-text-wrapper sub-heading">
                      Integrity
                    </div>
                    <p className="ourvalues-paragraph-1 sub-para">
                      At Mobilution, integrity is the bedrock of our actions. We
                      uphold the highest standards of honesty, transparency, and
                      ethical conduct. Even when faced with challenging
                      decisions, we are committed to doing what is right for our
                      customers, colleagues, and stakeholders.
                    </p>
                  </div>
                  <div className="ourvalues-card">
                    <div className="ourvalues-text-wrapper sub-heading">
                      Customer Centricity
                    </div>
                    <p className="ourvalues-paragraph-1 sub-para">
                      Our customers are the focal point of everything we do at
                      Mobilution. We are devoted to comprehending their needs,
                      offering personalized solutions, and delivering
                      exceptional service that consistently surpasses
                      expectations.
                    </p>
                  </div>
                </div>
                <div className="ourvalues-sub-container-card">
                  <div className="ourvalues-card-2">
                    <div className="ourvalues-text-wrapper sub-heading">
                      Collaboration
                    </div>
                    <p className="ourvalues-paragraph-1 sub-para">
                      Mobilution thrives in a collaborative and inclusive work
                      environment. We celebrate teamwork and diversity,
                      leveraging the unique strengths and perspectives of our
                      employees to foster innovation and achieve greater success
                      collectively.
                    </p>
                  </div>
                  <div className="ourvalues-card">
                    <div className="ourvalues-text-wrapper sub-heading">
                      Innovation
                    </div>
                    <p className="ourvalues-paragraph-1 sub-para">
                      Embracing change is integral to Mobilution&#39;s DNA. We
                      consistently seek innovative solutions to meet the
                      evolving needs of our customers. Our employees are
                      encouraged to think creatively, challenge conventions, and
                      explore new ideas to propel Mobilution into the forefront
                      of technological evolution.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="ourvalues-text-container">
                <div className="ourvalues-heading sub-header">
                  Mission &amp; Vision
                </div>
                <p className="ourvalues-paragraph sub-para">
                  At Mobilution IT Systems, our mission is to empower businesses
                  through transformative technology solutions, driving growth
                  and efficiency. Our vision is to be a catalyst for innovation,
                  shaping a future where organizations thrive in the
                  ever-evolving digital landscape.
                </p>
              </div>
              <div className="ourvalues-sub-container">
                <div className="ourvalues-image-mission">
                  <div className="ourvalues-image-container">
                    <img
                      className="ourvalues-abstract-design"
                      alt="Abstract design"
                      src={mission}
                    />
                  </div>
                  <div className="ourvalues-text-container-2">
                    <div className="ourvalues-mission-left-line">
                      <div className="ourvalues-text-wrapper sub-heading">
                        Mission
                      </div>
                      <p className="ourvalues-paragraph-1 sub-para">
                        At Mobilution IT Systems, our mission is to empower
                        businesses to achieve technological success. We are
                        committed to providing innovative IT solutions tailored
                        to their unique challenges. Through personalized
                        services, expert guidance, and state-of-the-art
                        technology, we strive to build enduring partnerships
                        with our clients. Our mission is to be their trusted
                        ally, assisting them in navigating the ever-evolving
                        technological landscape and turning their visions into
                        reality.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ourvalues-image-vision">
                  <div className="ourvalues-text-container-2">
                    <div className="ourvalues-vision-right-line">
                      <div className="ourvalues-text-wrapper sub-heading">
                        Vision
                      </div>
                      <p className="ourvalues-paragraph-1 sub-para">
                        At Mobilution IT Systems, our vision is to redefine the
                        IT solutions landscape by crafting a seamless and
                        personalized experience for our clients. We envision a
                        future where technology is accessible, transparent, and
                        precisely tailored to individual preferences. Through
                        relentless innovation and collaborative partnerships, we
                        aim to lead the industry, establishing new benchmarks
                        for customer-centric IT solutions. Our vision is to be
                        the preferred technology partner, recognized for our
                        unwavering commitment to excellence, trust, and client
                        satisfaction.
                      </p>
                    </div>
                  </div>
                  <div className="ourvalues-image-container-2">
                    <img
                      className="ourvalues-abstract-design"
                      alt="Abstract design"
                      src={vision}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurValues;
