import React from "react";
import "./css/header.css";
import { Link } from "react-router-dom";
import cloudImage from "../../images/cloud-header.png";
import mobileImage from "../../images/mobile-header.png";
import globeImage from "../../images/globe-header.png";
import dashboardImage from "../../images/dashboard-header.png";
import digitalImage from "../../images/security-header.png";

const Header = () => {
  return (
    <div className="container-fluid header-main   mt-5 mb-5">
      <div className="cirle-bg-blur">
        <div className="container ">
          <div className="header-main-container-page row ">
            <div className="col-lg-7 col-md-12 col-sm-12 ">
              <div className="header-div">
                <div className="frame-wrapper">
                  <h4 className="header-welcome">Welcome to Mobilution !</h4>

                  <div className="header-div-2">
                    <p className="header-banner header">
                      <h1 className="header-text-wrapper">
                        Elevating Possibilities with Mobilution:{" "}
                        <span className="header-text-span ">
                          Your IT Evolution Partner
                        </span>
                      </h1>
                    </p>
                    <p className="header-para ">
                      At Mobilution, we're driving the future of technology.
                      Experience the next level of IT solutions with our expert
                      services tailored for success.
                    </p>
                  </div>

                  <div className="header-more">
                    <button className="header-get-btn">
                      <Link
                        to="/aboutus"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        More
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="div-8 mt-4">
                <div className="header-right-div d-flex">
                  <div className="header-floats float-1">
                    <img
                      src={cloudImage}
                      alt="not found"
                      className="float-img-1"
                    />
                    <img
                      src={mobileImage}
                      alt="not found"
                      className="float-img-2"
                    />
                  </div>
                  <div>
                    <img src={globeImage} alt="not found" width={"100%"} />
                  </div>
                  <div className="header-floats float-2">
                    <img
                      src={dashboardImage}
                      alt="not found"
                      className="float-img-3"
                    />
                    <img
                      src={digitalImage}
                      alt="not found"
                      className="float-img-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
