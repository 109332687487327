import React, { useEffect, useState } from "react";
import "../css/ourServices.css";
import cloud from "../images/cloudimg.png";
import mobile from "../images/mobileimg.png";
import digital from "../images/digitalimg.png";
import product from "../images/productimg.png";
import business from "../images/businessimg.png";
import staff from "../images/staffimg.png";
import it from "../images/itimg.png";
import cloudsolutions from "../images/cloudsolutions.png";
import mobileapps from "../images/mobileapps.png";
import digitaltransformation from "../images/digitaltransformation.png";
import productengineering from "../images/productengineering.png";
import businessintellgence from "../images/businessintelligence.png";
import staffaugmentation from "../images/staffaugmentation.png";
import itimg from "../images/itconsulting.png";
import services from "../images/services-icon.png";

const OurServices = () => {
  const Tab1Content = () => (
    <>
      {" "}
      <div className="card digital-card " id="Cloud-Solutions">
        <div className="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={cloud}
                alt="Cloud Icon"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">Cloud Solutions </h1>
            </span>
          </div>

          <div>
            <div className="card-main">
              <div className="cards-main-left">
                <p className="solutions-header-para sub-para ">
                  In today's rapidly evolving digital landscape, businesses need
                  the agility, scalability, and security that traditional
                  on-premises solutions can't always provide. Cloud solutions
                  have emerged as a game-changer, enabling organizations to
                  streamline operations, enhance productivity, and stay
                  competitive in the digital age. This website is your gateway
                  to understanding the world of cloud solutions and how they can
                  transform your business. Harness the power of the cloud with
                  our tailored solutions. Whether you're looking to migrate to
                  the cloud, optimize your infrastructure, or ensure data
                  security, our team of experts has you covered. We'll craft a
                  cloud strategy that aligns with your business goals, ensuring
                  scalability, efficiency, and cost-effectiveness. In a
                  digital-first world, businesses need reliable and scalable
                  cloud solutions. Mobilution IT Systems offers a wide array of
                  cloud services, including:
                </p>
                <ul>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Cloud Migration
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Cloud Infrastructure Optimization
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Cloud Security
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Hybrid Cloud Solutions
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Data Backup and Recovery
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Cloud Cost Management
                  </li>
                </ul>
              </div>
              <div className="cards-main-right">
                <img
                  src={cloudsolutions}
                  alt=" not found"
                  width={"100%"}
                />
              </div>
            </div>
            {cloudShowMore && (
              <div>
                <p>Benefits of Cloud Solutions</p>
                <ul>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Agility: Cloud computing makes it easy to scale your
                    resources up or down on demand, so you can meet changing
                    business needs without having to invest in new hardware or
                    software.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Cost savings: Cloud computing can help you save money on IT
                    costs by eliminating the need to purchase and maintain your
                    own hardware and software.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Reliability: Cloud providers offer a high level of
                    reliability and uptime, so you can be confident that your
                    applications and data will be available when you need them.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Security: Cloud providers offer a variety of security
                    features to protect your data and applications, including
                    encryption, access control, and intrusion detection.
                  </li>
                </ul>

                <p className="solutions-header-para sub-para">
                  Embracing cloud solutions is not just a choice; it's a
                  strategic imperative for businesses aiming to stay competitive
                  and relevant in today's digital world. By exploring the vast
                  landscape of cloud services, you can unlock new possibilities,
                  streamline operations, and drive growth. Join us on this
                  journey as we unravel the power of cloud solutions and how
                  they can empower your business for a brighter, more efficient
                  future.
                </p>
              </div>
            )}
          </div>
          <div
            onClick={cloudSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {cloudShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );
  const Tab2Content = () => (
    <>
      {" "}
      <div class="card  digital-card  " id="Mobile-App">
        <div class="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={mobile}
                alt=" not found"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">Mobile App </h1>
            </span>
          </div>
          <div>
            <div className="card-main">
              <div className="cards-main-left">
                <p className=" solutions-header-para sub-para ">
                  In today's digital age, mobile apps have become an integral
                  part of our daily lives. Whether it's for personal
                  convenience, entertainment, or conducting business, mobile
                  apps have revolutionized the way we interact with technology.
                  As the world goes mobile, having a strong online presence and
                  a well-crafted mobile app is essential for businesses and
                  organizations to stay competitive and relevant. At Mobilution,
                  we understand the significance of mobile apps in the modern
                  world. We specialize in designing and developing mobile apps
                  that not only meet your business objectives but also provide
                  exceptional user experiences. Our team of experienced
                  developers and designers are passionate about creating
                  innovative solutions that will help your business thrive in
                  the mobile-first era.
                </p>

                <h1 className="services-mobile-heading">
                  Our Mobile App Development Services:
                </h1>
                <ul style={{ marginBottom: 0 }}>
                  <li className="list-items">
                    <span>
                      <img
                        src={services}
                        alt=" not found"
                      />{" "}
                    </span>
                    iOS and Android App Development: We have a team of skilled
                    developers proficient in creating apps for both iOS and
                    Android platforms. This ensures that your app can reach the
                    widest possible audience.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Custom App Development: No two businesses are the same, and
                    neither should their apps be. We tailor our app development
                    to suit your specific needs and requirements.
                  </li>
                </ul>
              </div>
              <div className="cards-main-right">
                <img
                  src={mobileapps}
                  width={"100%"}
                  alt="not found"
                />
              </div>
            </div>
          </div>

          {mobileShowMore && (
            <div>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  User-Centric Design: Our design team focuses on creating
                  intuitive and visually appealing user interfaces that make
                  your app a joy to use.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  App Maintenance and Support: We offer ongoing maintenance and
                  support services to ensure your app remains up-to-date and
                  bug-free.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  App Store Optimization (ASO): We help your app gain visibility
                  in app stores by optimizing its listing and improving its
                  search ranking.
                </li>
              </ul>
              <p className=" solutions-header-para sub-para ">
                In today's digital age, mobile apps have become an integral part
                of our daily lives. Whether it's for personal convenience,
                entertainment, or conducting business, mobile apps have
                revolutionized the way we interact with technology. As the world
                goes mobile, having a strong online presence and a well-crafted
                mobile app is essential for businesses and organizations to stay
                competitive and relevant. At Mobilution, we understand the
                significance of mobile apps in the modern world. We specialize
                in designing and developing mobile apps that not only meet your
                business objectives but also provide exceptional user
                experiences. Our team of experienced developers and designers
                are passionate about creating innovative solutions that will
                help your business thrive in the mobile-first era.
              </p>

              <h1 className="services-mobile-heading">
                Our Mobile App Development Services:
              </h1>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  iOS and Android App Development: We have a team of skilled
                  developers proficient in creating apps for both iOS and
                  Android platforms. This ensures that your app can reach the
                  widest possible audience.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Custom App Development: No two businesses are the same, and
                  neither should their apps be. We tailor our app development to
                  suit your specific needs and requirements.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  User-Centric Design: Our design team focuses on creating
                  intuitive and visually appealing user interfaces that make
                  your app a joy to use.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  App Maintenance and Support: We offer ongoing maintenance and
                  support services to ensure your app remains up-to-date and
                  bug-free.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  App Store Optimization (ASO): We help your app gain visibility
                  in app stores by optimizing its listing and improving its
                  search ranking.
                </li>
              </ul>

              <p className="services-mobile-heading">
                Let Us Help You Make the Most of Mobile Apps:
              </p>
              <p className="solutions-header-para sub-para">
                {" "}
                Mobilution is dedicated to helping businesses harness the full
                potential of mobile apps. Whether you're looking to increase
                your customer base, improve internal efficiency, or create a new
                revenue stream, we can guide you through the entire app
                development process. Our experienced team will work closely with
                you to bring your ideas to life.
              </p>
            </div>
          )}
          <div
            onClick={mobileSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {mobileShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );
  const Tab3Content = () => (
    <>
      {" "}
      <div class="card  digital-card " id="Digital-Transformation">
        <div className="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={digital}
                alt=" not found"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">
                Digital Transformation{" "}
              </h1>
            </span>
          </div>

          <div>
            <div className="card-main">
              <div className="cards-main-left">
                <p className="solutions-header-para sub-para ">
                  In today's fast-paced and hyper-connected world, staying ahead
                  of the curve is essential for businesses. Digital
                  transformation is the catalyst that propels your organization
                  into the future, redefining how you operate, engage with
                  customers, and innovate. Welcome to the digital age, where
                  opportunities abound, and the future is waiting to be shaped.
                  Understanding Digital Transformation Digital transformation is
                  more than just a buzzword; it's a fundamental shift in the way
                  we do business. It's about leveraging the latest technologies,
                  data, and digital tools to optimize processes, create better
                  experiences, and gain a competitive edge. It's a comprehensive
                  approach that touches every facet of your organization, from
                  operations and customer interactions to your overall strategy.
                  Why Digital Transformation Matters
                </p>
                <p className="services-mobile-heading">
                  {" "}
                  Why Digital Transformation Matters:
                </p>
                <ul style={{ marginBottom: 0 }}>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Enhanced Customer Experiences: In the digital age, customers
                    expect seamless, personalized interactions. Digital
                    transformation enables you to meet these expectations,
                    fostering loyalty and boosting satisfaction.
                  </li>
                </ul>
              </div>
              <div className="cards-main-right">
                <img
                  src={digitaltransformation}
                  width={"100%"}
                  alt="not found"
                />
              </div>
            </div>
            {digitalShowMore && (
              <div>
                <ul>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Operational Efficiency: Automation, data analytics, and AI
                    streamline processes, reduce costs, and increase
                    productivity.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Agility: The ability to adapt quickly to market changes is a
                    defining trait of successful businesses. Digital
                    transformation provides the agility needed to stay nimble in
                    a dynamic environment.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Data-Driven Decision Making: Access to real-time data
                    empowers you to make informed decisions, driving better
                    outcomes.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Competitive Advantage: Embracing digital transformation
                    allows you to outperform competitors, differentiate
                    yourself, and lead in your industry. Our Approach to Digital
                    Transformation
                  </li>
                </ul>
                <p className="services-mobile-heading">
                  {" "}
                  Our Approach to Digital Transformation:
                </p>

                <p>
                  {" "}
                  At Mobilution, we recognize that no two organizations are the
                  same. Our approach to digital transformation is a tailor-made
                  journey designed around your specific needs, objectives, and
                  challenges.
                </p>
                <ul>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Assessment: We begin by evaluating your current state,
                    pinpointing areas of improvement, and assessing your digital
                    readiness.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Strategy: We work closely with your team to create a
                    comprehensive digital strategy that aligns with your
                    business goals.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Technology Integration: We assist you in selecting and
                    implementing the right technology solutions, whether it's
                    cloud services, IoT, AI, or other digital tools.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Change Management: Successful digital transformation
                    involves cultural and organizational shifts. We provide
                    guidance and support to ensure your team embraces the new
                    digital mindset.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Continuous Improvement: We don't stop at implementation; we
                    assist in ongoing optimization and ensure you stay ahead of
                    evolving technology trends.
                  </li>
                </ul>

                <p className="services-mobile-heading">
                  {" "}
                  Our Services in Digital Transformation
                </p>
                <ul>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Digital Strategy: We create a clear roadmap for your digital
                    transformation journey.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Technology Integration: We help you choose and implement the
                    right technology solutions.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    Data Analytics: We help you harness the power of data for
                    informed decision-making.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>
                    Cybersecurity: We protect your digital assets with robust
                    cybersecurity solutions.
                  </li>
                  <li className="list-items">
                    <span>
                      <img src={services} alt=" not found" />{" "}
                    </span>{" "}
                    DChange Management: We ensure your team is prepared for
                    change and fully aligned with the digital transformation
                    process.
                  </li>
                </ul>
                <p>
                  {" "}
                  Are you ready to embark on your digital transformation
                  journey? Contact Mobilution today, and let's collaborate to
                  future-proof your business and drive innovation in this
                  rapidly evolving digital world. We're here to guide you every
                  step of the way. Digital transformation isn't just a
                  destination; it's a continuous journey of adaptation and
                  improvement. Join us at the forefront of this revolution, and
                  together, we'll shape the future of your business.
                </p>
              </div>
            )}
          </div>
          <div
            onClick={digitalSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {digitalShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );
  const Tab4Content = () => (
    <>
      {" "}
      <div class="card  digital-card " id="BusinessIntelligenceandAnalytics">
        <div class="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={business}
                alt=" not found"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">
                Business Intelligence and Analytics
              </h1>
            </span>
          </div>

          <div className="card-main">
            <div className="cards-main-left">
              <p>
                Unleashing the Power of Business Intelligence and Analytics - In
                today's data-driven world, businesses are inundated with vast
                amounts of information. However, the true value lies in the
                ability to harness this data and convert it into actionable
                insights. This is where Business Intelligence (BI) and Analytics
                come into play. In this digital age, businesses must embrace the
                power of BI and Analytics to stay competitive, adapt to market
                dynamics, and make informed decisions.
              </p>
              <div>
                <p className="services-mobile-heading">
                  {" "}
                  What is Business Intelligence and Analytics?
                </p>
                <p>
                  Business Intelligence and Analytics encompass a range of
                  technologies, strategies, and tools that allow organizations
                  to collect, analyse, and interpret data, ultimately aiding in
                  informed decision-making. These processes involve the
                  transformation of raw data into meaningful and useful
                  information for strategic planning and tactical execution.
                </p>
                <p className="services-mobile-heading"> The Key Components :</p>
              </div>
            </div>
            <div className="cards-main-right">
              <img
                src={businessintellgence}
                width={"100%"}
                alt="not found"
              />
            </div>
          </div>

          {businessShowMore && (
            <div>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Data Collection: BI and Analytics start with collecting data
                  from various sources, both internal and external. This data
                  includes financial records, customer information, sales data,
                  and much more.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Data Storage: After data collection, it needs to be stored
                  securely and efficiently. Data warehouses and databases are
                  used to ensure data integrity and accessibility.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Data Processing: The collected data is processed and cleaned
                  to remove errors or inconsistencies. It is then transformed
                  into a format that can be easily analysed.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Data Analysis: This is the heart of BI and Analytics. Various
                  techniques and tools are employed to extract meaningful
                  insights, trends, and patterns from the data.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Visualization: Translating data into visual formats like
                  graphs, charts, and dashboards makes it easier for
                  decision-makers to understand and interpret the information.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Reporting: Generating reports and summaries from the analysed
                  data provides a concise overview of the insights, making it
                  easier to communicate within the organization.
                </li>
              </ul>
              <p className="services-mobile-heading">
                The Benefits of BI and Analytics :
              </p>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Informed Decision-Making: BI and Analytics provide valuable
                  insights to guide decision-makers in choosing the most
                  effective strategies and solutions.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Competitive Advantage: Analyzing market trends and customer
                  behaviour allows businesses to stay ahead of the competition
                  and adapt to changing circumstances.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Efficiency Improvements: By identifying operational
                  inefficiencies and bottlenecks, businesses can streamline
                  their processes and reduce costs.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Improved Customer Experience: Understanding customer
                  preferences and behaviour enables companies to tailor their
                  products and services to meet specific needs.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Risk Mitigation: BI and Analytics help in identifying
                  potential risks and vulnerabilities, allowing businesses to
                  implement risk management strategies proactively.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Resource Optimization: Better resource allocation based on
                  data-driven insights ensures that resources are used
                  efficiently and effectively.
                </li>
              </ul>
              <p className="services-mobile-heading"> Use Case:</p>
              <p>
                {" "}
                BI and Analytics can be applied across various industries and
                domains:
              </p>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Retail: Analyzing sales data to optimize inventory management
                  and customer engagement.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Healthcare: Tracking patient data to enhance treatment
                  outcomes and healthcare services.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Finance: Detecting fraudulent transactions and optimizing
                  investment strategies.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Manufacturing: Monitoring production processes to minimize
                  downtime and improve quality.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Marketing: Evaluating the effectiveness of marketing campaigns
                  and customer segmentation.
                </li>
              </ul>
              <p className="services-mobile-heading">
                {" "}
                The Future of Business Intelligence and Analytics
              </p>
              <p>
                {" "}
                As technology continues to advance, BI and Analytics are
                evolving as well. With the integration of artificial
                intelligence, machine learning, and big data analytics,
                businesses will gain even more powerful tools to extract
                insights from their data. Business Intelligence and Analytics
                are not just buzzwords; they are essential tools for modern
                businesses. The ability to turn data into actionable insights
                empowers organizations to adapt, innovate, and thrive in a
                rapidly changing landscape. Embrace BI and Analytics to unlock
                the full potential of your data and stay ahead of the curve in
                today's competitive business environment.
              </p>
            </div>
          )}
          <div
            onClick={businessSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {businessShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );
  const Tab5Content = () => (
    <>
      {" "}
      <div class="card  digital-card " id="ProductEngineering">
        <div class="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={product}
                alt=" not found"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">
                Product Engineering
              </h1>
            </span>
          </div>
          <div className="card-main">
            <div className="cards-main-left">
              <p className="solutions-header-para sub-para ">
                Transforming Ideas into Cutting-Edge IT Products - Welcome to
                our IT Product Engineering Services! We are your trusted partner
                in bringing your innovative ideas to life, harnessing technology
                to create products that exceed your customers' expectations.
                With a proven track record of delivering high-quality IT
                products, we're here to help you turn your vision into a
                reality. What Are IT Product Engineering Services - IT Product
                Engineering Services encompass the end-to-end process of
                designing, developing, and maintaining software and hardware
                products. These services involve a seamless blend of creativity,
                technology, and a customer-centric approach to build products
                that solve real-world problems and streamline business
                operations.
              </p>
              <p className="services-mobile-heading"> Our Expertise</p>

              <p>
                {" "}
                At Mobilution, we have a team of dedicated professionals with a
                wealth of experience in IT product engineering. Our experts come
                from diverse backgrounds and possess in-depth knowledge of
                various technologies and industries. This enables us to create
                innovative solutions tailored to your unique requirements. Our
                core areas of expertise include: Software Development :
              </p>
            </div>
            <div className="cards-main-right">
              <img
                src={productengineering}
                width={"100%"}
                alt="not found"
              />
            </div>
          </div>

          {productShowMore && (
            <div>
              <span>
                Our skilled developers use the latest programming languages and
                tools to build robust, scalable, and user-friendly software
                solutions. User Experience (UX) and User Interface (UI) Design:
                We prioritize user-centric design to ensure that your product is
                intuitive and engaging. Quality Assurance and Testing: We
                rigorously test and validate our products to guarantee their
                performance and reliability. Product Maintenance and Support:
                Our commitment to your success extends beyond the product
                launch; we offer ongoing maintenance and support services.
                Customization and Integration: We can tailor our services to
                your unique requirements, ensuring that our solutions integrate
                seamlessly with your existing infrastructure.
              </span>

              <p className="services-mobile-heading">
                {" "}
                Why Choose Mobilution for IT Product Engineering?
              </p>

              <p>
                Proven Track Record: We have successfully delivered IT products
                for clients in various industries, from startups to established
                enterprises. Client-Centric Approach: Your goals and visions are
                our top priority. We work closely with you to understand your
                objectives and deliver results that align with your vision.
                Innovation and Creativity: Our team thrives on innovation and
                creative problem-solving. We're not just developers; we're your
                technology partners. State-of-the-Art Technology: We stay
                updated with the latest trends and technologies to ensure your
                products are built with the best tools available. Quality
                Assurance: Our rigorous testing and quality control processes
                ensure that your product functions flawlessly. Scalability: We
                design products that are ready to grow with your business,
                adapting to increased demand and evolving requirements.
              </p>
              <p className="services-mobile-heading"> Our Process</p>
              <p>
                {" "}
                Project Initiation: We work with you to understand your concept
                and objectives, defining the scope, budget, and timeline for
                your project. Design and Development: Our team of experts
                collaborates to design and develop your product, keeping you in
                the loop through regular updates. Testing and Quality Assurance:
                We meticulously test every aspect of your product to ensure it
                meets the highest quality standards. Product Launch: We assist
                with the launch of your product, providing support and guidance.
                Maintenance and Support: Our team remains available to maintain
                and support your product, making necessary updates and
                improvements.
              </p>
              <h1 className="services-Product-engineering-heading">
                Get Started Today
              </h1>
              <p>
                Ready to turn your innovative idea into a market-ready IT
                product? Contact us to get started. We look forward to helping
                you achieve your technology goals and deliver exceptional
                products that make a difference.
              </p>
            </div>
          )}
          <div
            onClick={productSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {productShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );
  const Tab6Content = () => (
    <>
      {" "}
      <div class="card  digital-card " id="StaffAugmentation">
        <div class="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={staff}
                alt="not found"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">
                Staff Augmentation
              </h1>
            </span>
          </div>
          <div className="card-main">
            <div className="cards-main-left">
              <p>
                In today's dynamic business landscape, organizations must remain
                agile to thrive and adapt to ever-changing market demands. Staff
                augmentation has emerged as a powerful solution to meet the
                flexibility and scalability required by modern enterprises. This
                strategy allows businesses to extend their capabilities by
                seamlessly integrating temporary professionals into their
                existing teams. Staff augmentation is a strategic workforce
                management solution that enables organizations to bolster their
                in-house teams by hiring external talent on a temporary or
                project basis. This approach empowers companies to access a
                diverse range of skills and expertise without the long-term
                commitment of full-time hiring. Staff augmentation services can
                be tailored to meet the unique needs and objectives of each
                organization.
              </p>
              <p className="services-mobile-heading">
                Key Benefits of Staff Augmentation:
              </p>
              <ul style={{ marginBottom: 0 }}>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Flexibility: Staff augmentation provides businesses with the
                  agility to scale their workforce up or down as needed,
                  responding to market fluctuations, project requirements, or
                  seasonal demands.
                </li>
              </ul>
            </div>
            <div className="cards-main-right">
              <img
                src={staffaugmentation}
                width={"100%"}
                alt="not found"
              />
            </div>
          </div>

          {staffShowMore && (
            <div>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Cost-Efficiency: By only paying for the skills and hours
                  required, staff augmentation minimizes overhead costs,
                  including benefits and long-term salary commitments associated
                  with permanent hires.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Access to Specialized Skills: Organizations can tap into a
                  vast pool of specialized professionals, including developers,
                  designers, project managers, and industry experts, who can
                  bring fresh insights and expertise to projects.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Rapid Onboarding: Staff augmentation allows companies to
                  quickly integrate skilled professionals, reducing
                  time-to-market and enhancing productivity.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Risk Mitigation: Companies can access top-tier talent without
                  the risks associated with long-term employment, such as
                  severance costs and HR challenges.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Focus on Core Competencies: By outsourcing non-core functions
                  to staff augmentation partners, organizations can concentrate
                  on their core business objectives.
                </li>
              </ul>

              <p className="services-mobile-heading">
                {" "}
                When to Consider Staff Augmentation:
              </p>
              <p>
                Staff augmentation is an ideal solution in various scenarios,
                including:
              </p>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Seasonal Demands: When your business experiences fluctuations
                  in demand throughout the year, such as during holiday seasons,
                  hiring temporary staff can help you meet short-term
                  requirements.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Project-Based Needs: For specific projects or initiatives that
                  require specialized skills, you can augment your team with
                  experts who have the necessary experience.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Skill Shortages: When you lack in-house expertise, staff
                  augmentation can provide access to the right professionals to
                  fill the gap.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Cost Constraints: If budget limitations make it challenging to
                  hire full-time employees, staff augmentation is a
                  cost-effective alternative.
                </li>
              </ul>

              <p className="services-mobile-heading">
                {" "}
                Why Choose Our Staff Augmentation Services:
              </p>
              <p>
                At Mobilution, we specialize in delivering tailor-made staff
                augmentation solutions that align with your organization's
                unique goals and needs. Here's why you should choose us:
              </p>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Extensive Talent Network: We have a vast network of highly
                  skilled professionals across various industries, ensuring that
                  you receive the expertise you need.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Customized Solutions: Our team works closely with you to
                  understand your requirements and design a staff augmentation
                  strategy that seamlessly integrates with your existing
                  operations.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Quality Assurance: We maintain strict quality control measures
                  to ensure the professionals we provide are of the highest
                  Caliber and meet your expectations.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Proven Track Record: With a history of successful staff
                  augmentation partnerships, we have the experience and
                  expertise to help you meet your business objectives.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Long-Term Relationship: We are committed to building lasting
                  partnerships and providing ongoing support, even after your
                  immediate needs are met.
                </li>
              </ul>
              <p>
                In a world where adaptability and efficiency are essential for
                business success, staff augmentation is a valuable strategy. By
                leveraging external talent to complement your team, you can
                enhance your organization's capabilities, maintain
                competitiveness, and drive growth. Ready to explore the benefits
                of staff augmentation? Contact Mobilution today to discuss how
                our customized solutions can help your business thrive in a
                dynamic marketplace.
              </p>
            </div>
          )}
          <div
            onClick={staffSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {staffShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );
  const Tab7Content = () => (
    <>
      <div class="card  digital-card " id="ItConsulting">
        <div class="card-body mobilution-card m-3">
          <div className="cards-heading">
            <span>
              <img
                src={it}
                alt="not found"
              />
            </span>
            <span>
              <h1 className="sub-heading solutions-header">IT Consulting</h1>
            </span>
          </div>
          <div className="card-main">
            <div className="cards-main-left">
              <p>
                At Mobilution, we understand the critical role that technology
                plays in today's business landscape. We believe that every
                organization, regardless of its size or industry, can harness
                the power of technology to drive growth, efficiency, and
                innovation. That's where our IT consulting services come into
                play. Who We Are Mobility in Technological Evolution is
                Mobilution. It means being “mobile” or “keep moving upwards” in
                the evolutionary process of the technological world. Mobilution
                IT is a dynamic and forward-thinking IT consulting firm with a
                proven track record of helping businesses leverage technology to
                achieve their goals. Our team of experienced IT professionals
                provides strategic guidance, solving complex problems, and
                delivering measurable results for our clients.
              </p>
              <p className="services-mobile-heading"> Our Services</p>
              <ul style={{ marginBottom: 0 }}>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  IT Strategy and Planning: We work closely with your
                  organization to develop a customized IT strategy that aligns
                  with your business objectives. Whether it's enhancing
                  cybersecurity, optimizing your IT infrastructure, or adopting
                  new technologies, we provide a roadmap for success.
                </li>
              </ul>
            </div>
            <div className="cards-main-right">
              <img
                src={itimg}
                width={"100%"}
                alt="not found"
              />
            </div>
          </div>

          {itShowMore && (
            <div>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  IT Security and Compliance: Protecting your organization from
                  cyber threats and ensuring compliance with industry
                  regulations is non-negotiable. Our IT security experts can
                  help you fortify your defences and maintain data integrity.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Cloud Solutions: Embrace the power of cloud computing. Our
                  experts can guide you through the selection, implementation,
                  and management of cloud solutions that enhance scalability,
                  reduce costs, and improve accessibility.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Business Process Optimization: Streamline your operations and
                  boost productivity with our business process optimization
                  services. We identify inefficiencies and implement innovative
                  solutions to enhance workflow and cut unnecessary costs.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  Data Analytics and Business Intelligence: Data is your most
                  asset. Our data analysts and business intelligence experts
                  help you harness the potential of data-driven decision-making
                  to gain a competitive edge.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  IT Infrastructure Management: Keep your IT infrastructure in
                  peak condition with our proactive monitoring and management.
                  We ensure your systems run smoothly, minimizing downtime and
                  maximizing performance. Why Choose Mobilution IT?
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>
                  IT Infrastructure Management: Keep your IT infrastructure in
                  peak condition with our proactive monitoring and management.
                  We ensure your systems run smoothly, minimizing downtime and
                  maximizing performance. Why Choose Mobilution IT?
                </li>
              </ul>
              <p className="services-mobile-heading">
                {" "}
                Why Choose Mobilution IT?
              </p>
              <ul>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Experience: With years of experience in IT consulting, we have
                  a deep understanding of the technology landscape. We've
                  successfully helped numerous businesses achieve their IT
                  objectives.
                </li>{" "}
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Tailored Solutions: We recognize that every organization is
                  unique. Our consulting services are customized to address your
                  specific needs, ensuring the best possible outcomes.
                </li>{" "}
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Expert Team: Our team of highly skilled and certified IT
                  consultants stays up to date with the latest industry trends
                  and technologies. They are passionate about finding innovative
                  solutions to your challenges.
                </li>{" "}
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Cost-Effective: Our services are designed to provide a strong
                  return on investment. We help you make wise IT investments and
                  control costs effectively.
                </li>
                <li className="list-items">
                  <span>
                    <img src={services} alt=" not found" />{" "}
                  </span>{" "}
                  Long-Term Partnership: We believe in building lasting
                  relationships with our clients. We are there to support your
                  IT needs through the various stages of your business's growth.
                </li>
              </ul>
              <p>
                Get Started Ready to unlock the full potential of your IT
                infrastructure? Contact Mobilution IT today for a consultation.
                Let's work together to drive your business to new heights
                through strategic IT consulting.
              </p>
            </div>
          )}
          <div
            onClick={itSeeMore}
            style={{ cursor: "pointer", color: "#F58131" }}
          >
            {itShowMore ? "See Less" : "See More"}
          </div>
        </div>
      </div>
    </>
  );

  const [cloudShowMore, setCloudShowMore] = useState(false);
  const [digitalShowMore, setDigitalShowMore] = useState(false);
  const [mobileShowMore, setMobileShowMore] = useState(false);
  const [productShowMore, setProductShowMore] = useState(false);
  const [businessShowMore, setBusinessShowMore] = useState(false);
  const [staffShowMore, setStaffShowMore] = useState(false);
  const [itShowMore, setItShowMore] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const cloudSeeMore = () => {
    setCloudShowMore(!cloudShowMore);
  };
  const digitalSeeMore = () => {
    setDigitalShowMore(!digitalShowMore);
  };
  const mobileSeeMore = () => {
    setMobileShowMore(!mobileShowMore);
  };
  const productSeeMore = () => {
    setProductShowMore(!productShowMore);
  };
  const businessSeeMore = () => {
    setBusinessShowMore(!businessShowMore);
  };
  const staffSeeMore = () => {
    setStaffShowMore(!staffShowMore);
  };
  const itSeeMore = () => {
    setItShowMore(!itShowMore);
  };

  const [activeTab, setActiveTab] = useState("Cloud Solutions");
  const [activeOption, setActiveOption] = useState("Cloud Solutions");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    handleTabClick(activeOption);
  }, [activeOption]);

  return (
    <>
      <div className="container-fluid ourservices-head-main ">
        <div className="cirle-bg-blur ">
          <div className="container ">
            <div className="our-services mt-5 mb-5">
              <div className="our-services-border">
                <h6 className="ourservices-header para">OUR SERVICES</h6>
                <h1 className="our-services-header header">
                  Elevate Your{" "}
                  <span className="header-span">Possibilities</span>{" "}
                </h1>
                <p className="sub-para services-content ">
                  Transform your brand with our innovation digital solutions
                  that captivate and engage your audience.
                </p>
              </div>
            </div>
            <div className="">
              <div className="comprehensive-solutions">
                <h1 className="header-solution">
                  Discover Our Comprehensive Solutions
                </h1>
                <p className=" subpara-1 sub-para ">
                  Explore Mobilution's array of services designed to elevate
                  your business. From cutting-edge technology solutions to
                  tailored strategies, we offer a diverse portfolio to meet your
                  unique needs. Our services are crafted to empower your
                  organization, driving innovation and success in the digital
                  age.
                </p>
              </div>

              <div className="solutions">
                <div className="card-solutions">
                  {screenWidth < 1200 ? (
                    <div className="tab-section">
                      <select
                        onChange={(e) => setActiveTab(e.target.value)}
                        className="tab-select-option"
                      >
                        <option selected value="Cloud Solutions">
                          Cloud Solutions
                        </option>
                        <option value="Mobile App">Mobile App</option>
                        <option value="Digital Transformation">
                          Digital Transformation
                        </option>
                        <option value="Business Intelligence">
                          Business Intelligence
                        </option>
                        <option value="Product Engineering">
                          Product Engineering
                        </option>
                        <option value="Staff Augmentation">
                          Staff Augmentation
                        </option>
                        <option value="It Consulting">It Consulting</option>
                      </select>
                      <div class="down_note"></div>
                    </div>
                  ) : (
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "Cloud Solutions" ? "active" : ""
                          } services-btn`}
                          aria-current="page"
                          onClick={() => handleTabClick("Cloud Solutions")}
                        >
                          Cloud Solutions
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "Mobile App" ? "active" : ""
                          } services-btn`}
                          onClick={() => handleTabClick("Mobile App")}
                        >
                          Mobile App
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "Digital Transformation"
                              ? "active"
                              : ""
                          } services-btn`}
                          onClick={() =>
                            handleTabClick("Digital Transformation")
                          }
                        >
                          Digital Transformation
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "Business Intelligence"
                              ? "active"
                              : ""
                          } services-btn`}
                          onClick={() =>
                            handleTabClick("Business Intelligence")
                          }
                        >
                          Business Intelligence
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "Product Engineering" ? "active" : ""
                          } services-btn`}
                          onClick={() => handleTabClick("Product Engineering")}
                        >
                          Product Engineering
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "Staff Augmentation" ? "active" : ""
                          } services-btn`}
                          onClick={() => handleTabClick("Staff Augmentation")}
                        >
                          Staff Augmentation
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          className={`nav-link ${
                            activeTab === "It Consulting" ? "active" : ""
                          } services-btn`}
                          onClick={() => handleTabClick("It Consulting")}
                        >
                          IT Consulting
                        </span>
                      </li>
                    </ul>
                  )}

                  <>
                    {activeTab === "Cloud Solutions" && <Tab1Content />}
                    {activeTab === "Mobile App" && <Tab2Content />}
                    {activeTab === "Digital Transformation" && <Tab3Content />}
                    {activeTab === "Business Intelligence" && <Tab4Content />}
                    {activeTab === "Product Engineering" && <Tab5Content />}
                    {activeTab === "Staff Augmentation" && <Tab6Content />}
                    {activeTab === "It Consulting" && <Tab7Content />}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
