import React from "react";
import "./css/hireyour.css";
import { Link } from "react-router-dom";
const HireYour = () => {
  return (
    <>
      <div className="container-fluid mt-5 ">
        <div className="container hire-your-container">
          <div className="hireyour-sub-container">
            <div className="hireyour-text-wrapper">
              <h1 className="hireyour-heading">
                Connect with us for more details
              </h1>
              <p className="hireyour-paragraph sub-para">
                Explore our comprehensive services, including IT staffing,
                telecom solutions, product development, and more. Let us help
                your business thrive in today's technology-driven landscape.
              </p>
              <button className="hireyour-contact-button ">
                <Link to="/contactus" className="hireyour-link">
                  Contact Us
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HireYour;
