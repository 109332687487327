import React from "react";
import "../css/careers.css";
import { ArrowUpRightCircleFill } from "react-bootstrap-icons";
const Careers = () => {
  return (
    <>
      <div className="container-fluid careers-head-main">
        <div className="cirle-bg-blur">
          <div className="container ">
            <div className="careers-container mt-5">
              <div className="careers-main ">
                <div className="careers-border">
                  <h6 className="careers-header para">CAREERS</h6>
                  <h3 className="careers-sub-header header">
                    Where Innovation Takes{" "}
                    <span className="careers-sub-header-span">
                      Center Stage!
                    </span>
                  </h3>
                  <p className="careers-content sub-para">
                    Join our team for a journey filled with innovation,
                    collaboration, and growth.
                  </p>
                </div>
              </div>

              <div className="careers-text-container mt-5">
                <div className="careers-opportunity-heading">
                  <p className="careers-heading sub-header">
                    Welcome to Mobilution, where talent meets opportunity!
                  </p>
                </div>
                <div className="careers-opportunity-para">
                  <p className="careers-paragraph sub-para">
                    Welcome to Mobilution, the intersection of talent and
                    opportunity! Join us in a dynamic culture where your skills
                    soar and possibilities unfold.
                  </p>
                </div>
                <div className="careers-current-opportunities">
                  <a
                    href="https://mobiltuionit.talentrecruit.com/career-page"
                    className="career-opportunity-click para"
                  >
                    Current Opportunities <ArrowUpRightCircleFill />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
