import React from "react";
import "./css/whyUs.css";
import chooseus1 from "../../images/chooseus1-home.png";
import chooseus2 from "../../images/chooseus2-home.png";
import chooseusicon from "../../images/chooseus-icon.png";

const WhyUs = () => {
  return (
    <>
      <div className="container-fluid Why-choose-us-container mt-5">
        <div className="container ">
          <div className="WhyUs row gx-0">
            <div className="col-lg-7 col-sm-12 col-md-12 px-4">
              <div className="">
                <div className="why-us-div">
                  <div className="frame-wrapper">
                    <div className="div-2">
                      <p className="why-choose-us header">
                        <span className="whyus-text-wrapper">Why </span>
                        <span className="why-us-span">Choose Us</span>
                      </p>
                      <p className="at-mobilution-we-re sub-para">
                        At Mobilution, we&#39;re not just a technology solution
                        provider; we&#39;re your strategic partner in success.
                        Choose us for our unwavering commitment to innovation,
                        collaborative approach, and a relentless pursuit of
                        excellence that sets your business on a trajectory of
                        continuous growth and success.
                      </p>
                    </div>
                  </div>

                  <div className="div-3">
                    <div className="div-5">
                      <img className="fi" alt="Fi" src={chooseusicon} />
                      <div className="div-6">
                        <div className="text-wrapper-2 para">
                          <strong>Expert Team</strong>
                        </div>
                        <p className="sub-para whyus-para">
                          Our tech-savvy professionals are passionate about what
                          they do.
                        </p>
                      </div>
                    </div>
                    <div className="div-5">
                      <img className="fi" alt="Fi" src={chooseusicon} />
                      <div className="div-6">
                        <div className="text-wrapper-2 para">
                          <strong>Innovation</strong>
                        </div>
                        <p className="sub-para whyus-para">
                          We&#39;re all about finding creative solutions that
                          make a real impact.
                        </p>
                      </div>
                    </div>
                    <div className="div-5">
                      <img className="fi" alt="Fi" src={chooseusicon} />
                      <div className="div-6">
                        <div className="text-wrapper-2 para">
                          <strong>Collaboration</strong>
                        </div>
                        <p className=" sub-para  whyus-para">
                          Your goals are our goals. We work closely with you for
                          tailored solutions.
                        </p>
                      </div>
                    </div>
                    <div className="div-5">
                      <img className="fi" alt="Fi" src={chooseusicon} />
                      <div className="div-6">
                        <div className="text-wrapper-2 para">
                          <strong> Comprehensive Services</strong>
                        </div>
                        <p className=" sub-para  whyus-para">
                          Cloud, Apps, Engineering, Intelligence – we&#39;ve got
                          the tools to boost your business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-lg-5 col-sm-12 col-md-12 d-flex justify-content-center">
              <div className="circle-img ">
                <div className="d-flex justify-content-start">
                  <img src={chooseus2} alt=" not found" />
                </div>
                <div className="d-flex justify-content-end">
                  <img src={chooseus1} alt=" not found" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
