import React from "react";
import "./css/aboutUs.css";
import aboutus from "../../images/aboutus-home.png";

const AboutUs = () => {
  return (
    <>
      <div className="aboutus-container-main ">
        <div className="cirle-bg-blur">
          <div className="container">
            <div className="aboutus-container  row">
              <div className=" gx-0 justify-content-center col-lg-6 col-sm-12">
                <div className=" justify-content-center">
                  <div className="aboutus-image-wrapper">
                    <img
                      className="aboutus-image  img-fluid"
                      alt="Icon container"
                      src={aboutus}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 ">
                <div className="aboutus-main">
                  <div className="aboutus-sub-main">
                    <div className="aboutus-main-head">
                      <p className="aboutus-about-us">
                        <h1 className="aboutus-text-wrapper-about sub-header">
                          About <span className="aboutus-span">Us</span>
                        </h1>
                      </p>
                      <p className="aboutus-text-wrapper sub-para">
                        MOBILUTION – Mobility in Technological Evolution is
                        Mobilution. It means being “mobile” or “keep moving
                        upwards” in the evolutionary process of the
                        technological world!
                      </p>
                      <p className="aboutus-text-wrapper sub-para">
                        Mobilution IT Systems is your one-stop destination for
                        innovative IT solutions. We specialize in a wide range
                        of services, including Cloud Solutions, Mobile Apps,
                        Product Engineering, Business Intelligence and
                        Analytics, and Staff Augmentation. With a mission to
                        empower businesses through technology, we are here to
                        take your organization to new heights.
                      </p>
                    </div>
                    <div className="aboutus-sub-headings">
                      <div className="aboutus-text-wrapper-header  sub-heading">
                        Who We Are:
                      </div>

                      <p className="aboutus-text-wrapper sub-para">
                        Mobilution IT Systems is your go-to for modern IT
                        solutions. From Cloud Services to Mobile Apps and
                        Business Intelligence, we cover it all. Our aim is
                        simple: to help your business thrive in the
                        ever-evolving tec landscape.
                      </p>
                    </div>
                    <div className="aboutus-sub-headings">
                      <div className="aboutus-text-wrapper-header sub-heading">
                        Our Mission:
                      </div>
                      <p className="aboutus-text-wrapper sub-para">
                        We&#39;re here to empower businesses through technology.
                        Our team is dedicated to bringing you the best
                        solutions, combining expertise and innovation to drive
                        your success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
