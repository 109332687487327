import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Arrow90degDown,
  ArrowRight,
  CaretLeft,
  CaretRight,
  Person,
  PersonCircle,
  StarFill,
} from "react-bootstrap-icons";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "./css/globalCompanies.css";

const GlobalCompanies = () => {
  const companies = [
    {
      image: <PersonCircle />,
      rating: 5,
      title: " Mobilution's Impact on Our Digital Journey",
      review:
        "Mobilution partner played a pivotal role in transforming our digital landscape. Their expertise, seamless collaboration, and innovative solutions significantly accelerated our journey towards technological excellence. Grateful for a partnership that consistently. ",
      name: "Shaifali Holani ",
      position: "CEO, Tech Innovators Inc.",
    },
    {
      image: <PersonCircle />,
      rating: 5,
      title: "Revolutionizing Our Business Landscape",
      review:
        "Switching to cloud solutions transformed our business operations overnight. The seamless scalability and accessibility have streamlined our workflow, boosting efficiency and productivity. Thanks to cloud technology, we're now future-ready and more resilient than ever.",
      name: "Sarah Johnson",
      position: "Srinivas Madhavaram ",
    },
    {
      image: <PersonCircle />,
      rating: 4,
      title: "Mobilution's Exceptional Resource Delivery",
      review:
        "Mobilution has been instrumental in elevating our team by consistently delivering top-notch Resoruces. Their strategic approach and deep understanding of our needs have truly set them apart, making them an invaluable asset to our growth and success+C25.",
      name: "Sai Kumar",
      position: "VP of Operations, Online Emporium",
    },
    {
      image: <PersonCircle />,
      rating: 5,
      title:
        "Transformative Partnership: Mobilution's Impact on Our Digital Journey",
      review:
        "Mobilution partner played a pivotal role in transforming our digital landscape. Their expertise, seamless collaboration, and innovative solutions significantly accelerated our journey towards technological excellence. ",
      name: "Shaifali Holani ",
      position: "CEO, Tech Innovators Inc.",
    },
    {
      image: <PersonCircle />,
      rating: 5,
      title: "Revolutionizing Our Business Landscape",
      review:
        "Switching to cloud solutions transformed our business operations overnight. The seamless scalability and accessibility have streamlined our workflow, boosting efficiency and productivity. Thanks to cloud technology, we're now future-ready and more resilient than ever.",
      name: "Sarah Johnson",
      position: "Srinivas Madhavaram ",
    },
    {
      image: <PersonCircle />,
      rating: 4,
      title: "Mobilution's Exceptional Resource Delivery",
      review:
        "Mobilution has been instrumental in elevating our team by consistently delivering top-notch Resoruces. Their strategic approach and deep understanding of our needs have truly set them apart, making them an invaluable asset to our growth and success+C25.",
      name: "Sai Kumar",
      position: "VP of Operations, Online Emporium",
    },
  ];

  return (
    <>
      <div className="container-fluid p-0 mt-5 golbal-companies-container">
        <div className="container">
          <div className="globleCompanies-main-container ">
            <div className="globleCompanies-head-part ">
              <div className="globleCompanies-head-content ">
                <h1 className="globleCompanies-heading">
                  Trusted by
                  <span className="globleCompanies-heading-sub">
                    Global Companies
                  </span>
                </h1>
                <p className="globleCompanies-paragraph sub-para">
                  Simplify your hiring process with Mobilution. Discover our
                  proven method to connect with the best IT professionals for
                  your business.
                </p>
              </div>
              <div className="globleCompanies-head-buttons">
                <div className="swiper-button-prev">
                  <CaretLeft
                    className="globleCompanies-CaretLeft"
                    size={30}
                    style={{ color: "#ffffff" }}
                  />
                </div>
                <div className="swiper-button-next">
                  <CaretRight
                    className="globleCompanies-CaretLeft"
                    size={30}
                    style={{ color: "#ffffff" }}
                  />
                </div>
              </div>
            </div>

            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={50}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {companies.map((data, index) => (
                <SwiperSlide key={index} className="global-company-card">
                  <div
                    className="globleCompanies-card "
                    style={{ width: "24rem" }}
                  >
                    <div className="globleCompanies-card-body">
                      <p className=" mt-2">
                        {Array.from({ length: data.rating }, (_, i) => (
                          <StarFill
                            className="globleCompanies-ratings"
                            key={i}
                          />
                        ))}
                      </p>
                      <h5 className="globleCompanies-card-title para">{`"${data.title}"`}</h5>

                      <p className="globleCompanies-card-text sub-para">
                        {data.review}
                      </p>
                    </div>
                    <div className="globleCompanies-icon-main">
                      <div className="globleCompanies-person-icon">
                        <PersonCircle
                          className="Person-icon"
                          size={50}
                          style={{ color: "#F58131" }}
                        />
                      </div>
                      <div className="globleCompanies-person-name">
                        <h6 className="globleCompanies-review-head  para">
                          {data.name}
                        </h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalCompanies;
